// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Snackbar.css */
.snackbar {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure it appears on top */
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  
  .snackbar.fade-out {
    opacity: 0;
  }
  /* Snackbar.css */
.snackbar {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  
  .snackbar.success {
    background-color: #4caf50; /* Green */
  }
  
  .snackbar.error {
    background-color: #f44336; /* Red */
  }
  
  .snackbar.warning {
    background-color: #ff9800; /* Yellow */
  }
  
  .snackbar.fade-out {
    opacity: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Snackbar/Snackbar.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa,EAAE,6BAA6B;IAC5C,UAAU;IACV,6BAA6B;EAC/B;;EAEA;IACE,UAAU;EACZ;EACA,iBAAiB;AACnB;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,UAAU;IACV,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB,EAAE,UAAU;EACvC;;EAEA;IACE,yBAAyB,EAAE,QAAQ;EACrC;;EAEA;IACE,yBAAyB,EAAE,WAAW;EACxC;;EAEA;IACE,UAAU;EACZ","sourcesContent":["/* Snackbar.css */\n.snackbar {\n    position: fixed;\n    top: 20px;\n    right: 20px;\n    background-color: #333;\n    color: #fff;\n    padding: 10px 20px;\n    border-radius: 4px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n    z-index: 1000; /* Ensure it appears on top */\n    opacity: 1;\n    transition: opacity 0.5s ease;\n  }\n  \n  .snackbar.fade-out {\n    opacity: 0;\n  }\n  /* Snackbar.css */\n.snackbar {\n    position: fixed;\n    top: 20px;\n    right: 20px;\n    background-color: #333;\n    color: #fff;\n    padding: 10px 20px;\n    border-radius: 4px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n    z-index: 1000;\n    opacity: 1;\n    transition: opacity 0.5s ease;\n  }\n  \n  .snackbar.success {\n    background-color: #4caf50; /* Green */\n  }\n  \n  .snackbar.error {\n    background-color: #f44336; /* Red */\n  }\n  \n  .snackbar.warning {\n    background-color: #ff9800; /* Yellow */\n  }\n  \n  .snackbar.fade-out {\n    opacity: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
