import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link, useLocation } from "react-router-dom";
import "./Drawer.css";
import logo from "../../assets/logo.svg"; // Replace with the path to your logo
import custom from "../../assets/custom.svg";
import general from "../../assets/clipboard.svg";
import vision from "../../assets/vison-ai.svg"; // Replace with the path to your Vision AI icon

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const StyledListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: "#EFEFEF",
    "&:hover": {
      backgroundColor: "#EFEFEF",
    },
  }),
}));

export default function DrawerComponent({ open, handleDrawerToggle }) {
  const location = useLocation();
  const [expanded, setExpanded] = useState({});

  // Initialize expanded state based on the current route
  useEffect(() => {
    const path = location.pathname;
    setExpanded({
      "custom-doc-validation": path.startsWith("/my-ai"),
      "doc-ai": path.startsWith("/doc-ai"),
      "vision-ai": path.startsWith("/vision-ai"),
    });
  }, [location.pathname]);

  const handleToggle = (item) => {
    setExpanded((prev) => ({ ...prev, [item]: !prev[item] }));
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? drawerWidth : 60,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? drawerWidth : 60,
          boxSizing: "border-box",
          transition: "width 0.3s",
        },
      }}
    >
      <DrawerHeader>
        {open && <img src={logo} alt="Logo" style={{ height: 40 }} />}
        <IconButton onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      </DrawerHeader>
      <List>
        {/* My AI Menu */}
        <ListItem disablePadding>
          <StyledListItemButton
            component={Link}
            // to="/custom-document-validation"
            // selected={location.pathname.startsWith("/my-ai")}
            onClick={() => handleToggle("custom-doc-validation")}
          >
            <ListItemIcon>
              <img src={custom} alt="Custom" style={{ height: 20 }} />
            </ListItemIcon>
            {open && <ListItemText primary="My AI" />}
            {open &&
              (expanded["custom-doc-validation"] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              ))}
          </StyledListItemButton>
        </ListItem>
        <Collapse
          in={expanded["custom-doc-validation"]}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/my-ai/criminal-certification"
                selected={location.pathname.startsWith(
                  "/my-ai/criminal-certification"
                )}
              >
                {open && <ListItemText primary="Criminal certificate" />}
              </StyledListItemButton>
            </ListItem>
          </List>
        </Collapse>

        {/* Doc AI Menu */}
        <ListItem disablePadding>
          <StyledListItemButton
            component={Link}
            // to="/doc-ai"
            // selected={location.pathname.startsWith("/doc-ai")}
            onClick={() => handleToggle("doc-ai")}
          >
            <ListItemIcon>
              <img src={general} alt="General" style={{ height: 20 }} />
            </ListItemIcon>
            {open && <ListItemText primary="Doc AI" />}
            {open &&
              (expanded["doc-ai"] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              ))}
          </StyledListItemButton>
        </ListItem>
        <Collapse in={expanded["doc-ai"]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/doc-ai/invoice"
                selected={location.pathname.startsWith("/doc-ai/invoice")}
              >
                {open && <ListItemText primary="Invoice" />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/doc-ai/receipt"
                selected={location.pathname.startsWith("/doc-ai/receipt")}
              >
                {open && <ListItemText primary="Receipt" />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/doc-ai/business-card"
                selected={location.pathname.startsWith("/doc-ai/business-card")}
              >
                {open && <ListItemText primary="Business Card" />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/doc-ai/credit-card"
                selected={location.pathname.startsWith("/doc-ai/credit-card")}
              >
                {open && <ListItemText primary="Credit Card" />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/doc-ai/id-document"
                selected={location.pathname.startsWith("/doc-ai/id-document")}
              >
                {open && <ListItemText primary="Id Document" />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/doc-ai/general-document"
                selected={location.pathname.startsWith("/doc-ai/general-document")}
              >
                {open && <ListItemText primary="General Document" />}
              </StyledListItemButton>
            </ListItem>
          </List>
        </Collapse>

        {/* Vision AI Menu */}
        <ListItem disablePadding>
          <StyledListItemButton
            component={Link}
            // to="/vision-ai"
            // selected={location.pathname.startsWith("/vision-ai")}
            onClick={() => handleToggle("vision-ai")}
          >
            <ListItemIcon>
              <img src={vision} alt="Vision AI" style={{ height: 20 }} />
            </ListItemIcon>
            {open && <ListItemText primary="Vision AI" />}
            {open &&
              (expanded["vision-ai"] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              ))}
          </StyledListItemButton>
        </ListItem>
        <Collapse in={expanded["vision-ai"]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/face-detection"
                selected={location.pathname.startsWith("/vision-ai/face-detection")}
              >
                {open && <ListItemText primary="Face Detection" />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/liveness-detection"
                selected={location.pathname.startsWith("/vision-ai/liveness-detection")}
              >
                {open && <ListItemText primary="Liveness Detection" />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/product-deduction"
                selected={location.pathname.startsWith("/vision-ai/product-deduction")}
              >
                {open && <ListItemText primary="Product Deduction" />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/image-search"
                selected={location.pathname.startsWith("/vision-ai/image-search")}
              >
                {open && <ListItemText primary="Image Search" />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/video-search"
                selected={location.pathname.startsWith("/vision-ai/video-search")}
              >
                {open && <ListItemText primary="Video Search" />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/video-summary"
                selected={location.pathname.startsWith("/vision-ai/video-summary")}
              >
                {open && <ListItemText primary="Video Summary" />}
              </StyledListItemButton>
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
}
