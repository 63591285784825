// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.carousel-images {
    display: flex;
    overflow: hidden;
    width: 100px;
    height: 100%;
    /* x: 100px; */
    position: relative;
}

.carousel-images img {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.carousal-btn {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
}

.carousal-btn:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/components/PreviewComponent/Carousal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,sCAAsC;AAC1C;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".carousel-container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-top: 20px;\n}\n\n.carousel-images {\n    display: flex;\n    overflow: hidden;\n    width: 100px;\n    height: 100%;\n    /* x: 100px; */\n    position: relative;\n}\n\n.carousel-images img {\n    min-width: 100%;\n    height: 100%;\n    object-fit: cover;\n    transition: transform 0.3s ease-in-out;\n}\n\n.carousal-btn {\n    background-color: transparent;\n    border: none;\n    font-size: 2rem;\n    cursor: pointer;\n}\n\n.carousal-btn:disabled {\n    cursor: not-allowed;\n    opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
