// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-cveggr-MuiListItemIcon-root {
    min-width: 36px !important;
  }

  .css-11biola-MuiDrawer-docked .MuiDrawer-paper {
    background: #F9F9F9;
  }
  .css-36tmq2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    background: #EFEFEF !important;
    border-radius: 10px;
  }
 
  /* .css-1s5q7ck-MuiListItem-root {
    height: 32px;
    border-radius: 10px;
  }
  .css-1s5q7ck-MuiListItem-root :hover {
    height: 30px;
  }
  .css-36tmq2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    height: 30px;
  } */`, "",{"version":3,"sources":["webpack://./src/components/Drawer/Drawer.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;EAC5B;;EAEA;IACE,mBAAmB;EACrB;EACA;IACE,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;;;;;;;;;KASG","sourcesContent":[".css-cveggr-MuiListItemIcon-root {\n    min-width: 36px !important;\n  }\n\n  .css-11biola-MuiDrawer-docked .MuiDrawer-paper {\n    background: #F9F9F9;\n  }\n  .css-36tmq2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {\n    background: #EFEFEF !important;\n    border-radius: 10px;\n  }\n \n  /* .css-1s5q7ck-MuiListItem-root {\n    height: 32px;\n    border-radius: 10px;\n  }\n  .css-1s5q7ck-MuiListItem-root :hover {\n    height: 30px;\n  }\n  .css-36tmq2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {\n    height: 30px;\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
