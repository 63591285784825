import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import DrawerComponent from "./components/Drawer/Drawer";
import MainComponent from "./components/MainComponent/MainComponent";
import Login from "./components/Login/Login";
import './App.css'

function MainContent() {
  const location = useLocation();
  const [show, setShow] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    setShow(location.pathname !== "/login");
  }, [location]);

  return (
    <>
      {show && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <DrawerComponent open={open} handleDrawerToggle={handleDrawerToggle} />
          <MainComponent open={open} />
        </Box>
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* Add more routes here if needed */}
      </Routes>
    </>
  );
}

export default function PersistentDrawerLeft() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}
