import React from 'react';
import { IconButton, Box } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const NavigationComponent = ({ onPrevious, onNext, isPreviousDisabled, isNextDisabled }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50px',
        // padding: '4px 8px',
      }}
    >
      <IconButton onClick={onPrevious} disabled={isPreviousDisabled} sx={{ color: 'black' }}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <IconButton onClick={onNext} disabled={isNextDisabled} sx={{ color: 'black' }}>
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default NavigationComponent;
