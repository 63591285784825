import React from 'react';
import './Modal.css'; // Import the CSS file for the modal

const Modal = ({ isOpen, onClose, imageUrl }) => {
  if (!isOpen) return null;

  return (
    <div className="modal" style={{ display: 'block' }}>
      <span className="close" onClick={onClose}>&times;</span>
      <img className="modal-content" src={imageUrl} alt="QR Code" />
      {/* <div className="modal-content-caption">QR Code</div> */}
    </div>
  );
};

export default Modal;
