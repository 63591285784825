// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  display: flex !important;
}

.css-1wuqoxd {
  padding: 0px 20px !important;
}
.css-y3y00b {
  padding: 0px 20px !important;
}

@media (min-width: 600px) {
  .css-1k455el {
      min-height: 0 !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,4BAA4B;AAC9B;AACA;EACE,4BAA4B;AAC9B;;AAEA;EACE;MACI,wBAAwB;EAC5B;AACF","sourcesContent":[".app {\n  display: flex !important;\n}\n\n.css-1wuqoxd {\n  padding: 0px 20px !important;\n}\n.css-y3y00b {\n  padding: 0px 20px !important;\n}\n\n@media (min-width: 600px) {\n  .css-1k455el {\n      min-height: 0 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
