// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.admin-contact-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
}

.admin-contact-card {
  background-color: #F9F9F9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 600px;
  text-align: center;
}

.admin-contact-image {
  width: 200px;
  margin-bottom: 20px;
}

.admin-contact-card h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.admin-contact-card p {
  font-size: 16px;
  color: #666;
}

.admin-contact-card a {
  color: #0073e6;
  text-decoration: none;
}

.admin-contact-card a:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/GeneralDocValidation/GeneralDoc.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;EACxC,aAAa;EACb,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".admin-contact-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n}\n\n.admin-contact-header {\n  font-size: 24px;\n  font-weight: bold;\n  margin-bottom: 20px;\n  text-align: left;\n  width: 100%;\n}\n\n.admin-contact-card {\n  background-color: #F9F9F9;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  padding: 40px;\n  max-width: 600px;\n  text-align: center;\n}\n\n.admin-contact-image {\n  width: 200px;\n  margin-bottom: 20px;\n}\n\n.admin-contact-card h2 {\n  font-size: 18px;\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n\n.admin-contact-card p {\n  font-size: 16px;\n  color: #666;\n}\n\n.admin-contact-card a {\n  color: #0073e6;\n  text-decoration: none;\n}\n\n.admin-contact-card a:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
