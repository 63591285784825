import React, { useState, useRef, useEffect } from 'react';
import './Header.css'
import { useNavigate } from 'react-router-dom';
import Initial from '../../assets/initial.svg'; // Replace with your actual image path

const HeaderComponent = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleImageClick = () => {
    setDropdownOpen(prev => !prev);
  };
  const firstName = localStorage.getItem('firstName') || '';
  const lastName = localStorage.getItem('lastName') || '';
  const initial= `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleMenuClick = (action) => {
    console.log(action); // Replace with your actual action handlers
    setDropdownOpen(false);
    if(action === 'logout') {
      localStorage.clear()
      navigate('/')
    }
  };

  return (
    <div className='header-container'>
      <div className='header-initial' onClick={handleImageClick}>
        {initial}
      </div>
      {dropdownOpen && (
        <div ref={dropdownRef} className='dropdown-menu'>
          <div onClick={() => handleMenuClick('profile')} className='dropdown-item'>
            My Profile
          </div>
          <div onClick={() => handleMenuClick('user-management')} className='dropdown-item'>
            User Management
          </div>
          <div onClick={() => handleMenuClick('logout')} className='dropdown-item'>
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderComponent;
