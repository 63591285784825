import React, { useState } from "react";
import "./Carousal.css"; // Import the CSS file for styling
import Modal from "../../Modal";

const ManualCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const openQRModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeQRModal = () => {
    setIsModalOpen(false);
    setModalImageUrl("");
  };

  return (
    <>
      <div className="carousel-container">
        <button
          className="carousal-btn"
          onClick={handlePrev}
          disabled={currentIndex === 0}
        >
          &lt;
        </button>
        <div className="carousel-images">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`carousal`}
              className={index === currentIndex ? "active" : ""}
              onClick={() => openQRModal(image)}
              style={{ transform: `translateX(${-currentIndex * 100}%)` }}
            />
          ))}
        </div>
        <button
          className="carousal-btn"
          onClick={handleNext}
          disabled={currentIndex === images.length - 1}
        >
          &gt;
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeQRModal}
        imageUrl={modalImageUrl}
      />
    </>
  );
};

export default ManualCarousel;
