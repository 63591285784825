import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import { useSelector } from 'react-redux';

const CustomBreadCrumbs = (props) => {
  const [breadCrumbData, setBreadCrumbData] = React.useState([]);

  React.useEffect(() => {
    setBreadCrumbData(props.array);
  }, [props.array]);

  return (
    <>
      <Stack spacing={2} >
        <Breadcrumbs
        style={{marginBottom:'5px'}} 
          separator={
            <NavigateNextIcon fontSize='small' style={{ color: '#40408b' }} />
          }
          
          aria-label='breadcrumb'
        >
          {breadCrumbData.map((item, index) => (
            <Link
              underline={item.underline}
              color={item.color}
              key={index?.toString()}
              href={item.href}
              
            >
              {item.name}
            </Link>
          ))}
        </Breadcrumbs>
      </Stack>
    </>
  );
};

export default CustomBreadCrumbs;
