import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import View from "./assets/view.svg";
import Delete from "./assets/delete.svg";
import "./MyTable.css";
// import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";

import "./CriminalCertificateValidation.css";

const MyTable = ({ records }) => {
  console.log(records);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const data = records.map((item, index) => {
      return {
        id: index,
        ...item,
      };
    });

    setRows(data);
  }, []);
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // "short" for abbreviated month name
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const addTooltipToCell = (params) => (
    <Tooltip
      title={params.value ? params.value.toString() : ""}
      arrow
      placement="left"
    >
      <span
        style={{ cursor: "pointer" }}
        className="file-name"
        onClick={() => handleFileClick(params.row.id, params.row.file_name)}
      >
        {params.value}
      </span>
    </Tooltip>
  );

  const handleFileClick = (id, file_name) => {
    navigate(`/my-ai/criminal-certificate/preview/${id}`);
  };

  const tableHeight = `calc(100vh - 180px)`;

  const renderDate = (params) => {
    return <div>{formatDate(params.value)}</div>;
  };

  const renderExtractedFields = (params) => {
    const extractedValuesArray = Object.values(params.row.boundary)
      .filter((item) => item.ValueString)
      .map((item) => item.ValueString);

    const maxChips = 3; // Maximum number of chips to display
    // const filteredFields = availableRows.filter((field) => params.row[field]);
    // console.log(filteredFields)
    console.log(extractedValuesArray);
    const displayedFields = extractedValuesArray.slice(0, maxChips);
    const hiddenFields = extractedValuesArray.slice(maxChips);
    const hiddenFieldsCount =
      extractedValuesArray.length - displayedFields.length;
    const chipStyles = [
      { backgroundColor: "#EAECF0", color: "black" },
      { backgroundColor: "#F7E8EF", color: "#6A1039" },
      { backgroundColor: "#E8EEF7", color: "#6C86AF" },
    ];

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {displayedFields.map((field, index) => (
          <Chip
            key={field}
            label={field}
            variant="outlined"
            size="small"
            style={{
              margin: "2px",
              ...chipStyles[index],
              border: 0,
              width: "130px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          />
        ))}
        {hiddenFieldsCount > 0 && (
          <Tooltip
            title={
              <Box
                sx={{
                  display: "flex",
                  background: "white",
                  flexWrap: "wrap",
                  gap: "4px",
                  p: 1, // Padding to create some space around the chips
                }}
              >
                {hiddenFields.map((field, index) => (
                  <Chip
                    key={field}
                    label={field}
                    variant="outlined"
                    size="small"
                    style={{ margin: "2px", border: 0 }}
                  />
                ))}
              </Box>
            }
            arrow
            placement="top"
          >
            <Chip
              key="more"
              label={`+${hiddenFieldsCount}`}
              variant="outlined"
              size="small"
              style={{
                margin: "2px",
                backgroundColor: "#F2F4F7",
                color: "#344054",
              }}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const renderActions = (params) => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <img
          style={{ cursor: "pointer" }}
          src={View}
          onClick={() => handleFileClick(params.row.id, params.row.file_name)}
          alt="View"
          height={20}
        />
        <img
          style={{ cursor: "not-allowed" }}
          src={Delete}
          alt="Delete"
          height={20}
        />
      </div>
    );
  };
  const columns = [
    {
      field: "file_name",
      headerName: "File Name",
      flex: 2,
      headerClassName: "app-theme-header",
      renderCell: (params) => addTooltipToCell(params),
    },
    {
      field: "created_on",
      headerName: "Uploaded On",
      flex: 2,
      headerClassName: "app-theme-header",
      renderCell: renderDate,
    },
    {
      field: "extracted",
      headerName: "Fields Detected",
      flex: 5,
      headerClassName: "app-theme-header",
      renderCell: (params) => renderExtractedFields(params),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "app-theme-header",
      renderCell: renderActions,
    },
  ];
  return (
    <Box
      sx={{
        height: tableHeight,
        width: "100%",
        "& .app-theme-header": {
          backgroundColor: "#EAECF0",
          color: "black",
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        className="datagrid-class"
        pageSize={5}
        rowsPerPageOptions={[5, 10]}
        disableSelectionOnClick
        disableRowSelectionOnClick
        disableColumnSelector
        disableColumnResize
        disableColumnMenu
      />
    </Box>
  );
};

export default MyTable;
