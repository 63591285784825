// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.general-doc-validation {
  display: flex;
}

.konva-container {
  flex: 1 1;
}

.konva-scroll {
  overflow: auto;
}

.konva-scroll-inner {
  padding: 10px;
}

.konva-stage {
  border: 1px solid black;
}
/* .upload-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  position: relative;
} */


.panel {
  width: 300px;
  border-left: 1px solid black;
}

.container {
  padding: 10px;
}

.data-item {
  /* margin-bottom: 10px; */
  padding: 5px;
}

.data-item.highlight {
  border: 2px solid red;
}
.konvajs-content {
  margin: auto;
}

.label {
  font-weight: bold;
}

.value {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PreviewComponent/PreviewComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB;AACzB;AACA;;;;;;;;;GASG;;;AAGH;EACE,YAAY;EACZ,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".general-doc-validation {\n  display: flex;\n}\n\n.konva-container {\n  flex: 1;\n}\n\n.konva-scroll {\n  overflow: auto;\n}\n\n.konva-scroll-inner {\n  padding: 10px;\n}\n\n.konva-stage {\n  border: 1px solid black;\n}\n/* .upload-button {\n  background-color: black;\n  color: white;\n  padding: 10px 20px;\n  border: none;\n  cursor: pointer;\n  border-radius: 5px;\n  font-size: 16px;\n  position: relative;\n} */\n\n\n.panel {\n  width: 300px;\n  border-left: 1px solid black;\n}\n\n.container {\n  padding: 10px;\n}\n\n.data-item {\n  /* margin-bottom: 10px; */\n  padding: 5px;\n}\n\n.data-item.highlight {\n  border: 2px solid red;\n}\n.konvajs-content {\n  margin: auto;\n}\n\n.label {\n  font-weight: bold;\n}\n\n.value {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
