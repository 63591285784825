// MyModal.js
import React, { useState, useEffect, useRef } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import axios from "axios";
import "./CriminalCertificateValidation.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiService from "./service";
import { useNavigate } from 'react-router-dom';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Analyse = ({ isOpen, onClose, from }) => {
  console.log(from)
  const [files, setFiles] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState(null);
  const timeoutRef = useRef(null);
  const [fileSizeError, setFileSizeError] = useState('')
  const userId = localStorage.getItem('userId')
  const navigate = useNavigate();
  const [request_id, setRequestId] = useState(null);
  const fileInputRef = useRef(null);
  const formRef = useRef(null);

  const [failedDocuments, setFailedDocuments] = useState([]);
  // const [allData, setAllData] = useState([]);
  const [successfulDocuments, setSuccessfulDocuments] = useState([]);

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  // useEffect(() => {
  //   return () => {
  //     setFileSizeError('')
  //   }
  // }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(from === 'my-ai') {
      if (files.length === 0) {
        toast.error("Please select at least one file");
        return;
      }
  
      setLoading(true); // Set loading to true when submitting
      setMessage("Processing...");
  
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
  
      timeoutRef.current = setInterval(() => {
        setMessage((prevMessage) => {
          const messages = [
            "Processing...",
            "Still analyzing...",
            "Hang tight, almost there...",
            "Just a moment more...",
            "Almost done...",
          ];
          return messages[(messages.indexOf(prevMessage) + 1) % messages.length];
        });
      }, 7000);
  
      try {
        const response =  await apiService.analyzeData(formData, userId)
        clearInterval(timeoutRef.current);
  
        if (response.status) {
          toast.success(response.message);
          setRequestId(response.data);
          setMessage("response.message");
          setMessageType("success");
          onClose()
          navigate(`/my-ai/criminal-certificate/preview/${response.data.inserted_id}`);
         
          // setFailedDocuments(response.data.failedDocuments); // Set failed documents
          // setSuccessfulDocuments(response.data.successfulDocuments); // Set successful documents
        } else {
          toast.error(response.data.message);
          setMessage(response.data.message);
          setMessageType("error");
        }
      } catch (error) {
        toast.error(
          "Error analyzing document. Please upload a valid certificate in PDF format"
        );
        setMessage(
          "Error analyzing document. Please upload a valid certificate in PDF format"
        );
        setMessageType("error");
        console.error(error);
      } finally {
        setLoading(false); // Set loading back to false after response or error
      }
    }

    if(from === 'invoice') {
      if (files.length === 0) {
        toast.error("Please select at least one file");
        return;
      }
  
      setLoading(true); // Set loading to true when submitting
      setMessage("Processing...");
  
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
  
      timeoutRef.current = setInterval(() => {
        setMessage((prevMessage) => {
          const messages = [
            "Processing...",
            "Still analyzing...",
            "Hang tight, almost there...",
            "Just a moment more...",
            "Almost done...",
          ];
          return messages[(messages.indexOf(prevMessage) + 1) % messages.length];
        });
      }, 7000);
  
      try {
        const response =  await apiService.analyseInvoice(formData)
        clearInterval(timeoutRef.current);
  
        if (response.status) {
          toast.success(response.message);
          setRequestId(response.data);
          setMessage("response.message");
          setMessageType("success");
          onClose()
          navigate(`/doc-ai/invoice/preview/${response.data.inserted_id}`);
         
          // setFailedDocuments(response.data.failedDocuments); // Set failed documents
          // setSuccessfulDocuments(response.data.successfulDocuments); // Set successful documents
        } else {
          toast.error(response.data.message);
          setMessage(response.data.message);
          setMessageType("error");
        }
      } catch (error) {
        toast.error(
          "Error analyzing document. Please upload a valid certificate in PDF format"
        );
        setMessage(
          "Error analyzing document. Please upload a valid certificate in PDF format"
        );
        setMessageType("error");
        console.error(error);
      } finally {
        setLoading(false); // Set loading back to false after response or error
      }
    }
    if(from === 'general-document') {
      if (files.length === 0) {
        toast.error("Please select at least one file");
        return;
      }
  
      setLoading(true); // Set loading to true when submitting
      setMessage("Processing...");
  
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
  
      timeoutRef.current = setInterval(() => {
        setMessage((prevMessage) => {
          const messages = [
            "Processing...",
            "Still analyzing...",
            "Hang tight, almost there...",
            "Just a moment more...",
            "Almost done...",
          ];
          return messages[(messages.indexOf(prevMessage) + 1) % messages.length];
        });
      }, 7000);
  
      try {
        const response =  await apiService.analyseGenDocument(formData)
        clearInterval(timeoutRef.current);
  
        if (response.status) {
          toast.success(response.message);
          setRequestId(response.data);
          setMessage("response.message");
          setMessageType("success");
          onClose()
          navigate(`/doc-ai/general-document/preview/${response.data.inserted_id}`);
         
          // setFailedDocuments(response.data.failedDocuments); // Set failed documents
          // setSuccessfulDocuments(response.data.successfulDocuments); // Set successful documents
        } else {
          toast.error(response.data.message);
          setMessage(response.data.message);
          setMessageType("error");
        }
      } catch (error) {
        toast.error(
          "Error analyzing document. Please upload a valid certificate in PDF format"
        );
        setMessage(
          "Error analyzing document. Please upload a valid certificate in PDF format"
        );
        setMessageType("error");
        console.error(error);
      } finally {
        setLoading(false); // Set loading back to false after response or error
      }
    }

   
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if(selectedFile) {
      const fileSize = selectedFile.size;
      const maxSize = 3 * 1024 * 1024; // 5 MB size limit

      if (fileSize > maxSize) {
        setFileSizeError('File size exceeds 5 MB');
        setFiles(null);
      } else {
        setFiles(Array.from(e.target.files));
        setFileSizeError('');
        // setFile(selectedFile);
      }
      
    }
    
  };

  const handleNextButtonClick = () => {
    setMessage("");
    setFiles([]);
    fileInputRef.current.value = ""; // Reset the file input value
    setFailedDocuments([]); // Clear failed documents
    setSuccessfulDocuments([]);
  };

  useEffect(() => {
    if (isOpen) {
      // Clear the state when the modal opens
      setFileSizeError('');
    }
  }, [isOpen]);

  const handleCopyFailedDocuments = () => {
    const failedDocumentsText = failedDocuments.join("\n");
    navigator.clipboard.writeText(failedDocumentsText);
    toast.success("Failed documents copied to clipboard");
  };

  const handleCopySuccessDocuments = () => {
    const successDocumentsText = successfulDocuments.join("\n");
    navigator.clipboard.writeText(successDocumentsText);
    toast.success("Successfully processed documents copied to clipboard");
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <ToastContainer />
        <div className="ocr-form-container">
          <Box sx={style}>
            <Typography id="modal-title" variant="h6" component="h2">
              Analyse
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              <div>
                <label className="label" htmlFor="fileInput">
                  Note : You can upload single certificate
                  files in only PDF format.
                </label>
                <input
                  type="file"
                  style={{width : '100%'}}
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  
                />{" "}
              </div>
              {/* <form ref={formRef} onSubmit={handleSubmit}>
                
              </form> */}
            </Typography>
            <div className="button-container">
              <Button style={{border : '1px solid black', color:'black'}} onClick={onClose} variant="outlined">
                Close
              </Button>
              {fileSizeError && <p style={{color : 'red'}}>File size is larger than 3 MB</p>}
              {loading && (
                <div className="loader-container">
                  <div className="loader"></div>
                  <span>{message}</span>
                </div>
              )}
              
              <Button style={{background: 'black'}} onClick={handleSubmit} variant="contained">
                Analyse
              </Button>
            </div>
          </Box>
        </div>

        {/* Show success documents container if available */}
        {successfulDocuments.length > 0 && (
          <div className="successful-documents-container">
            <h3 className="successful-documents-header">
              Validated Documents
              <label
                className="copy-all-button-success"
                onClick={handleCopySuccessDocuments}
              >
                {" "}
                &nbsp; ❒
              </label>
            </h3>
            <ul className="successful-documents-list">
              {successfulDocuments.map((document, index) => (
                <li key={index}>{document}</li>
              ))}
            </ul>
          </div>
        )}

        {/* Show failed documents container if available */}
        {failedDocuments.length > 0 && (
          <div className="failed-documents-container">
            <h3 className="failed-documents-header">
              Failed Documents
              <label
                className="copy-all-button"
                onClick={handleCopyFailedDocuments}
              >
                {" "}
                &nbsp; ❒
              </label>
            </h3>
            <ul className="failed-documents-list">
              {failedDocuments.map((document, index) => (
                <li key={index}>{document}</li>
              ))}
            </ul>
          </div>
        )}
        {/* <div className="button-container">
          {message && (
            <Button
              className="next-button"
              onClick={handleNextButtonClick}
              variant="contained"
            >
              Analyse
            </Button>
          )}
        </div> */}
      </>
    </Modal>
  );
};

export default Analyse;
