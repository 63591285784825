import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./CriminalCertificateValidation.css"; // Import CSS for styling
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "./Modal"; // Import the Modal component
import MyTable from "./MyTable";
import Analyse from "./Analyse";
import Download from "./assets/Download.svg";
import CustomBreadCrumbs from "./components/BreadCrumb/BreadCrumb";
import Plus from "./assets/plus.svg";
import apiService from "./service";
import "./Modal.css";

const CriminalCertificateValidation = () => {
  const [data, setData] = useState([]);
  const [dataAvailable, setDataAvailable] = useState([]);
  const [showDataGrid, setShowDataGrid] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userId = localStorage.getItem('userId')
  const [isAnalyseOpen, setIsAnalyseOpen] = useState(false);
  const [showUploadDropdown, setShowUploadDropdown] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const toggleUploadDropdown = () => {
    setShowUploadDropdown(!showUploadDropdown);
  };

  const breadCrumbArray = [
    {
      name: "Criminal Certificate",
      color: "#767676",
      underline: "none",
      // href: "/my-ai/criminal-certification",
    },
  ];

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowUploadDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (data != null && data.length > 0) {
      console.log(JSON.parse(data));
      setDataAvailable(JSON.parse(data));

      // setDataAvailable(data.length > 0); // Update dataAvailable state based on data length
    }
  }, [data]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiService.getData(userId)
        console.log(response);
        setData(response);
        // setShowDataGrid(true);
        try {
          // Check if response.data.data is a valid JSON string and parse it
          const data = response?.length > 0 ? JSON.parse(response) : [];
          setDataAvailable(data);
      } catch (error) {
          console.error("Error parsing JSON data:", error);
          setDataAvailable([]); // Fallback to an empty array if parsing fails
      }
      setShowDataGrid(true);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const handleDownloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(dataAvailable);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0]; // Format: YYYY-MM-DD
    const formattedTime = currentDate.toLocaleTimeString().replace(/:/g, ""); // Format: HHMMSS

    const filename = `belgium_criminal_certificate_validation_${formattedDate}_${formattedTime}.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  const openQRModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeQRModal = () => {
    setIsModalOpen(false);
    setModalImageUrl("");
  };
  const closeAnalysModal = () => {
    setIsAnalyseOpen(false);
    setModalImageUrl("");
  };

  const openAnalyseModal = () => {
    // setModalImageUrl(imageUrl);
    setIsAnalyseOpen(true);
  };

  return (
    <div className="page-container">
      <CustomBreadCrumbs array ={breadCrumbArray} />
      <ToastContainer />
      <div className="ocr-form-container App">

        {dataAvailable && showDataGrid && (
          <div className="view-all-container ">
            <div className="button-container">
              <p></p>

              <div className="button-container-end">
                <button
                  onClick={handleDownloadExcel}
                  className="excel-download"
                >
                  <img
                    style={{ marginRight: 5 }}
                    src={Download}
                    alt="Download"
                    height={15}
                  />{" "}
                  Export
                </button>
                <button
                  ref={buttonRef}
                  // className="upload-button"
                  onClick={openAnalyseModal}
                  className="analyse-upload "
                >
                  <img src={Plus} alt="Upload" height={15} /> Upload
                </button>
              </div>
            </div>

            <MyTable records={dataAvailable} />
            {showUploadDropdown && (
              <div className="upload-dropdown" ref={dropdownRef}>
                <h3>Drag and Drop to upload files</h3>
                <p>PDF files not more than 5 MB</p>
                <div className="upload-actions">
                  <button className="select-file-button">Select File</button>
                  <button
                    className="cancel-button"
                    onClick={toggleUploadDropdown}
                  >
                    Cancel
                  </button>
                  <button className="analyze-button">Analyze</button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={closeQRModal}
        imageUrl={modalImageUrl}
      />
      <Analyse isOpen={isAnalyseOpen} onClose={closeAnalysModal} from ={'my-ai'} />
    </div>
  );
};

export default CriminalCertificateValidation;
