// Snackbar.js
import React, { useEffect, useState } from 'react';
import './Snackbar.css';

const Snackbar = ({ message, variant = 'success' }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        // Cleanup or any additional logic when closing
      }, 500); // Match the duration of fade-out transition
    }, 3000); // Show for 3 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`snackbar ${variant} ${fadeOut ? 'fade-out' : ''}`}>
      {message}
    </div>
  );
};

export default Snackbar;
