import React, { useState } from 'react';
import axios from 'axios';

const OCRForm = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!file) {
      setMessage('Please select a file');
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await axios.post('https://localhost:7088/api/controller', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (response.data.statusCode === 200) {
        setMessage(response.data.message);
      } else {
        setMessage('Error analyzing document. Please try again.');
      }

    } catch (error) {
      setMessage('Error analyzing document. Please try again.');
      console.error(error);
    }
  
  };

  return (
    <div>
      <h1>Belgium Criminal Certificate Validation</h1>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Analyze Document</button>
      </form>
      {message&& <p>{message}</p>}
    </div>
  );
};

export default OCRForm;