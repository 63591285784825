import React, { useRef, useState, useEffect } from "react";
import { Stage, Layer, Image, Rect } from "react-konva";
import axios from "axios";
import apiService from "../../service";
import { useParams } from "react-router-dom";
import NavigationComponent from "../NavigationComponent/NavigationComponent";
import CustomBreadCrumbs from "../BreadCrumb/BreadCrumb";
import "./PreviewComponent.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import ManualCarousel from './Carousal'

const colors = [
  "red",
  "blue",
  "green",
  "yellow",
  "purple",
  "orange",
  "pink",
  "cyan",
  "magenta",
];



const useImage = (src) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (src) {
      const img = new window.Image();
      img.src = src;
      img.onload = () => setImage(img);
      img.onerror = () => setError("Failed to load image");
    }
  }, [src]);

  return [image, error];
};

const DataLabels = {
  "SOI First Name": "First Name",
  "SOI Last Name": "Last Name",
  "Date of Birth": "Date of Birth",
  "Birth Place" :'Birth Place',
  "Date Of Issue": "Date Of Issue",
  blob_url: "Blob URL",
  "Records Section": "Records Section",
  "Certificate Number": "Certificate Number",
  "Authority Signature": "Authority Signature",
  "Issued Authority": "Issued Authority",
  Citizenship: "Citizenship",
  "Mother's name": "Mother's Name",
  "Father's name ": "Father's Name",
  "Remarks2": "Remarks2",
  "Remarks3": "Remarks3",
  "Remarks4": "Remarks4",
  "Remarks5": "Remarks5",
  "Verification Code" : "Verification Code",
  "Folio" :"Folio",
  "Remarks7": "Remarks7",
  "Identification Number": "Identification Number",
  "Expiration Date": "Expiration Date",
  "Document Number" :"Document Number",
  "Request Id" :"Request Id",
  "Purpose" :"Purpose",
  "Reference Number" : "Reference Number",
  "Records Section 1" : "Records Section 1",
  "Country of Birth" : "Country of Birth",
  "Date Of Expire" : "Date Of Expire",
  "Address" : "Address",
  "COC Number" : "COC Number",
  "Passport Number" : "Passport Number"
};

const PreviewComponent = () => {
  const [rectangles, setRectangles] = useState([]);
  const [hoveredRectIndex, setHoveredRectIndex] = useState(null);
  const [imageBlobUrls, setImageBlobUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // Use a single index for image and page
  const [image, error] = useImage(imageBlobUrls[currentIndex]);
  const stageRef = useRef(null);
  const [breadCrumb, setBreadCrumb] = useState([])
  const [fileName, setFileName] = useState('');
  const [maxIndex, setMaxIndex] = useState()
  const panelRef = useRef(null);

  const { id } = useParams();
  const [dataAvailable, setDataAvailable] = useState(null);

  const convertToPixels = (polygon, scaleFactor) => {
    return polygon.map((coord) => coord * scaleFactor);
  };

  const calculateBoundingBox = (polygon) => {
    const xCoords = polygon.filter((_, index) => index % 2 === 0);
    const yCoords = polygon.filter((_, index) => index % 2 !== 0);

    const xMin = Math.min(...xCoords);
    const xMax = Math.max(...xCoords);
    const yMin = Math.min(...yCoords);
    const yMax = Math.max(...yCoords);

    const width = xMax - xMin;
    const height = yMax - yMin;

    return {
      x: xMin,
      y: yMin,
      width,
      height,
    };
  };

  useEffect(() => {
    if (dataAvailable && dataAvailable.boundary) {
      const scaleFactor = 96; // Example scale factor
      const data = [];
      console.log(dataAvailable);
      setFileName(dataAvailable.fileName)
      const breadCrumbArray = [
        {
          name: "Criminal Certificate",
          color: "#000000",
          underline: "none",
          href: "/my-ai/criminal-certification",
        },
        {
          name: dataAvailable.file_name,
          color: "#767676",
          underline: "none",
        }
      ];
      setBreadCrumb(breadCrumbArray)
      setMaxIndex(dataAvailable.image_blob_url.length)
      for (const key in dataAvailable.boundary) {
        if (dataAvailable.boundary.hasOwnProperty(key)) {
          const boundary = dataAvailable.boundary[key];
          if (boundary.Boundary.length > 0) {
            console.log(currentIndex);
            if (
              boundary.Boundary[0].PageNumber === currentIndex + 1 &&
              boundary.Boundary &&
              boundary.Boundary.length > 0
            ) {
              console.log(boundary.Boundary);
              const polygon = boundary.Boundary[0].polygon;

              const boundingBoxPDF = calculateBoundingBox(polygon);
              const boundingBoxInPixels = convertToPixels(
                [
                  boundingBoxPDF.x,
                  boundingBoxPDF.y,
                  boundingBoxPDF.width,
                  boundingBoxPDF.height,
                ],
                scaleFactor
              );

              const increasedWidth = boundingBoxInPixels[2] + 5;
              const increasedHeight = boundingBoxInPixels[3] + 5;

              if (DataLabels[key]) {
                data.push({
                  [DataLabels[key]]: boundary.ValueString || boundary.Content,
                  x: boundingBoxInPixels[0],
                  y: boundingBoxInPixels[1],
                  width: increasedWidth,
                  height: increasedHeight,
                  color: colors[data.length % colors.length],
                });
              }
            }
          }
        }
      }

      setRectangles(data);
      setImageBlobUrls(dataAvailable.image_blob_url || []);
    }
  }, [dataAvailable, currentIndex]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiService.getDataById(id);
        const data = JSON.parse(response);
        setDataAvailable(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [id]);

  const handleMouseEnterRect = (index) => {
    setHoveredRectIndex(index);
  };

  const handleMouseLeaveRect = () => {
    setHoveredRectIndex(null);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex - 1));
  };

  const stageWidth = 800;
  const stageHeight = image
    ? (image.height * stageWidth) / image.width
    : window.innerHeight;

  return (
    <>
     <CustomBreadCrumbs array ={breadCrumb} />
     <div className="general-doc-validation">
      
      <div className="konva-container">
        <div className="konva-scroll">
        {imageBlobUrls.length > 1 && (
          <div style={{display : "flex",flexDirection : 'column', padding :'0px 20px', justifyContent :'center', alignItems :'center'}}>
            
           <NavigationComponent
          onPrevious={handlePrevious}
          onNext={handleNext}
          isPreviousDisabled={currentIndex === 0}
          isNextDisabled={currentIndex === maxIndex - 1}
        />
         <p style={{margin :0}}>{currentIndex + 1} of {maxIndex}</p>
       
          </div>
  
            )}
          <div className="konva-scroll-inner">
            <Stage
              className="konva-stage"
              width={stageWidth}
              height={stageHeight}
              ref={stageRef}
            >
              <Layer>
                {image && (
                  <Image
                    image={image}
                    width={stageWidth}
                    height={stageHeight}
                  />
                )}
                {rectangles.map((rect, index) => (
                  <Rect
                    key={index}
                    x={rect.x}
                    y={rect.y}
                    width={rect.width}
                    height={rect.height}
                    fill=""
                    stroke={hoveredRectIndex === index ? "red" : rect.color}
                    strokeWidth={2}
                    draggable
                    onMouseEnter={() => handleMouseEnterRect(index)}
                    onMouseLeave={handleMouseLeaveRect}
                    onDragEnd={(e) => {
                      const newRectangles = [...rectangles];
                      newRectangles[index] = {
                        ...rect,
                        x: e.target.x(),
                        y: e.target.y(),
                      };
                      setRectangles(newRectangles);
                    }}
                    onTransformEnd={(e) => {
                      const node = e.target;
                      const scaleX = node.scaleX();
                      const scaleY = node.scaleY();

                      node.scaleX(1);
                      node.scaleY(1);
                      const newRectangles = [...rectangles];
                      newRectangles[index] = {
                        ...rect,
                        x: node.x(),
                        y: node.y(),
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(5, node.height() * scaleY),
                      };
                      setRectangles(newRectangles);
                    }}
                  />
                ))}
              </Layer>
            </Stage>
          </div>
        </div>
      </div>
      <div className="panel" ref={panelRef}>
        <div className="container">
          {rectangles.map((item, index) => {
            const [key, value] = Object.entries(item).find(
              ([k]) =>
                k !== "x" &&
                k !== "y" &&
                k !== "width" &&
                k !== "height" &&
                k !== "color"
            );
            return (
              <div
                key={index}
                className={`data-item ${
                  hoveredRectIndex === index ? "highlight" : ""
                }`}
              >
                <div className="label">{key}</div>
                <div className="value">
                  {key === "Blob URL" ? (
                    <a href={value} target="_blank" rel="noopener noreferrer">
                      View Document
                    </a>
                  ) : (
                    value
                  )}
                </div>
              </div>
            );
          })}
             <div>
            {dataAvailable?.blob_url.length > 0  && <ManualCarousel images={dataAvailable?.blob_url} / >}
        </div>
        </div>
      </div>
 
      {error && <p>{error}</p>}
    </div>
    </>

  );
};

export default PreviewComponent;
