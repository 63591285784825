// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  display: flex;
  justify-content: flex-end; /* Align items to the end */
  gap: 60px; /* Space between items */
  margin: 20px 0px;
  align-items: center;
}

.header-initial {
  width: 40px;
  height: 40px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

.header-container {
  position: relative;
  display: flex;
  align-items: center;
}

.header-icon {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-item {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB,EAAE,2BAA2B;EACtD,SAAS,EAAE,wBAAwB;EACnC,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,uBAAuB;EACvB,sBAAsB;EACtB,kBAAkB;EAClB,yCAAyC;EACzC,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".header-container {\n  display: flex;\n  justify-content: flex-end; /* Align items to the end */\n  gap: 60px; /* Space between items */\n  margin: 20px 0px;\n  align-items: center;\n}\n\n.header-initial {\n  width: 40px;\n  height: 40px;\n  background-color: black;\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  cursor: pointer;\n  font-size: 18px;\n  font-weight: bold;\n}\n\n.header-container {\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\n.header-icon {\n  cursor: pointer;\n}\n\n.dropdown-menu {\n  position: absolute;\n  top: 100%;\n  right: 0;\n  background-color: white;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n}\n\n.dropdown-item {\n  padding: 10px 20px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.dropdown-item:hover {\n  background-color: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
