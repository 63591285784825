import React from "react";
import { useLocation } from "react-router-dom";
import Robot from '../../assets/robot.gif'
import './GeneralDoc.css'

const GeneralDocValidation = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean); // split and remove empty segments
  let headerText = "";

  if (pathSegments[0] === "doc-ai" || pathSegments[0] === "vision-ai" || pathSegments[0] === "my-ai") {
    // Set the header text based on the second parameter in the path
    headerText = pathSegments[1]
      ? `${pathSegments[1]
          .replace("-", " ")
          .replace(/\b\w/g, (char) => char.toUpperCase())}`
      : "Default Header";
  }

  return (
    <>
      <header className="header">
        <h2 style={{marginLeft : '20px'}}>{headerText}</h2>
      </header>
      <div className="admin-contact-container">
        {/* <h1 className="admin-contact-header">Receipt</h1> */}
        <div className="admin-contact-card">
          <img src={Robot} alt="Robot" className="admin-contact-image" />
          <h2>Contact Administrator</h2>
          <p>
            It looks like this page is currently unavailable for your
            organization. Please{" "}
            <span style={{fontWeight : 'bolder', textDecoration : 'underline'}}>contact your administrator </span>
            to request access or more information.
          </p>
        </div>
      </div>
    </>
  );
};

export default GeneralDocValidation;
