// src/services/apiService.js
import axios from 'axios';

const HttpClient = axios.create();

const API_URL = process.env.REACT_APP_API_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;
console.log(API_URL)



HttpClient.interceptors.request.use(
  (config) => {
    const tenant = localStorage.getItem('tenant');
    if (tenant) {
      config.headers.tenant = `${tenant}`;
    }
    console.log('Axios Request Config:', config)
    return config;
  },
  (error) => Promise.reject(error)
);

const getDataById = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/AIController/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getData = async (userId) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/AIController/all/${userId}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const analyzeData = async (formData, userId) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/v1/analyze`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        "user_id": userId
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const analyseInvoice = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/invoice`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
const analyseGenDocument = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/gendocument`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
const login = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/api/login`, data, {
      headers: {
        'Content-Type': 'application/json',
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error.response.data;
  }
};

const getInvoice = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/invoice`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const getInvoiceDataById = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/invoice/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getGenDocuments = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/gendocuments`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getGenDocumentDataById = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/gendocument/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const apiService = {
  getDataById,
  getData,
  analyzeData,
  login,
  getInvoice,
  getInvoiceDataById,
  analyseInvoice,
  analyseGenDocument,
  getGenDocuments,
  getGenDocumentDataById
};

export default apiService;