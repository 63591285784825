import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import BelgiumDocValidation from "../BelgimDocValidation/BelgiumDocValidation";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import GeneralDocValidation from "../GeneralDocValidation/GeneralDocValidation";
import GenDocumentPreview from "../GenDocument/GenDocumentPreview";
import GenDocument from "../GenDocument/GenDocument";
import BreadcrumbsComponent from "../BreadCrumb/BreadCrumb";
import HeaderComponent from "../Header/Header";
import InvoicePreview from "../Invoice/InvoicePreview";
import PreviewComponent from "../PreviewComponent/PreviewComponent";
import Invoice from "../Invoice/Invoice";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `0`,
    overflowY: "auto",
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MainComponent({ open }) {
  return (
    <Main open={open}>
      <DrawerHeader />
      <HeaderComponent />
      {/* <BreadcrumbsComponent /> */}
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route
          path="/my-ai/criminal-certification"
          element={<BelgiumDocValidation />}
        />
        <Route path="/my-ai/criminal-certificate/preview/:id" Component={PreviewComponent} />
        <Route path="/doc-ai/invoice" Component={Invoice} />
        <Route path="/doc-ai/invoice/preview/:id" Component={InvoicePreview} />
        <Route path="/doc-ai/general-document" Component={GenDocument} />
        <Route path="/doc-ai/general-document/preview/:id" Component={GenDocumentPreview} />
        {/* <Route
          path="/general-document-validation"
          element={<GeneralDocValidation />}
        /> */}
        <Route
          path="/doc-ai/*"
          element={<GeneralDocValidation />}
        />
           <Route
          path="/vision-ai/*"
          element={<GeneralDocValidation />}
        />
      </Routes>
    </Main>
  );
}
